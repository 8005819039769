@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.searchbg{
    background-color: #FF9A8B;
    background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%);    
}

.overlay-seach-bg{
    background-image: url("../src/images/boxes.png");
    background-size: 13%;
    background-position: center;
}